import { sectionToString } from '..'

import { Section, Subsection } from '@/types/document'

export const citePrompt = (section: Subsection | Section) => {
  return `RESPOND IN JSON.

TASK:
Your task is to provide scholarly citations in the body of the narrative text for the following section:

${sectionToString(section)}


RESPONSE FORMAT:
Return the enhanced section in valid JSON with the following structure:
{
  "title": "Section Title",
  "text": "Section text in markdown with citations in the body of the text. Example: 'This is a sentence with a citation (Name, year).' And List of references at the end of the text.",
  "subsections": [
    {
      "title": "Subsection Title",
      "text": "Subsection text in markdown with citations in the body of the text. Example: 'This is a sentence with a citation (Name, year).' And List of references at the end of the text."
    }
  ]
}


THERE MUST BE AT LEAST 1 CITATION PER PARAGRAPH.
AT THE END OF EACH SECTION AND SUBSECTION TEXT, INCLUDE A LIST OF REFERENCES.
RESPOND IN JSON.
`
}
