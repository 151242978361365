'use client'

import { RiBook2Line } from '@remixicon/react'
import { Button, Form, message } from 'antd'
import { useEffect } from 'react'

import useGrantApplicationState from '@/hooks/context/useGrantApplicationState'
import useModalsState from '@/hooks/context/useModalsState'

import ReferencesModal from '@/components/Chatbot/Output/Answer/ReferencesModal'

import { markdown } from '@/utils'
import { cn } from '@/utils/clsx'
import { generateProposalDraftPrompt } from '@/utils/prompts'

import LoadingScreen from '../LoadingScreen'
import ProgressButtons from '../ProgressButtons'
import lottieSearchingAnimation from '../../../../public/lottieSearching.json'

interface GrantInfoStepProps {
  getAnswerWrapper: (question: string, streaming: boolean) => Promise<void>
  loading?: boolean
  goBack: () => void
}

const GrantInfoStep: React.FC<GrantInfoStepProps> = ({
  getAnswerWrapper,
  loading,
  goBack,
}) => {
  const [form] = Form.useForm()
  const {
    questions,
    steps,
    setCurrentStep,
    currentStep,
    setSteps,
    setSections,
    setQuestions,
  } = useGrantApplicationState()
  const { toggleModal } = useModalsState()

  useEffect(() => {
    setSteps({
      ...steps,
      [currentStep]: {
        numQuestions: 1,
      },
    })
  }, [loading])

  const finish = async () => {
    const nextStep = currentStep + 1
    // Update the state variables
    setCurrentStep(nextStep)
    setSteps({
      ...steps,
      [nextStep]: {
        numQuestions: 1,
      },
    })
    try {
      await getAnswerWrapper(generateProposalDraftPrompt(), false)
    } catch (error) {
      console.error(error)
      message.error('Failed to generate proposal draft. Please try again.')
      setCurrentStep(nextStep - 1)
      setSteps({
        ...steps,
        [nextStep]: undefined,
      })
      setSections({
        sections: [],
        history: [],
        historyIndex: 0,
      })
      setQuestions((prevState) => prevState.slice(0, -1))
    }
  }

  return (
    <Form
      form={form}
      onFinish={finish}
      autoComplete='off'
      className='flex h-full grow flex-col'
      labelAlign='left'
    >
      <div className='flex h-[calc(100vh-110px)] w-full overflow-y-auto p-2'>
        <div
          id='scroller'
          className={cn(
            'm-auto flex w-full flex-col min-h-full rounded-lg bg-surface p-6 text-left text-on-surface sm:max-w-[70em] dark:bg-dark-surface dark:text-dark-on-surface',
            !questions[questions.length - 1]?.messages[1]?.message
              ? 'h-auto'
              : ''
          )}
        >
          {questions[questions.length - 1]?.messages[1]?.message ? (
            <>
              <div className='markdown-answer h-full grow break-words text-left text-sm sm:text-base'>
                {markdown(
                  questions[questions.length - 1]?.messages[1]
                    ?.message as string,
                  loading
                )}
              </div>
              <div id='anchor' className='opacity-0'>
                a
              </div>
            </>
          ) : (
            <LoadingScreen
              lottieAnimation={lottieSearchingAnimation}
              text={[
                'Identifying the selected grant for detailed analysis...',
                'Gathering specific data on funding eligibility and requirements...',
                'Analyzing grant terms to ensure alignment with your needs...',
                'Compiling relevant insights...',
                'Finalizing data for selected grant...',
              ]}
              timeInterval={10000}
              infiniteLoader
            />
          )}
        </div>
      </div>
      <ReferencesModal
        documents={
          questions[questions.length - 1]?.messages[1]?.documents ?? []
        }
      />
      <ProgressButtons
        additionalButtons={
          questions[questions.length - 1]?.messages[1]?.message &&
          !loading && (
            <Button
              disabled={loading}
              onClick={() => toggleModal('references')}
              icon={<RiBook2Line className='size-5' />}
              className='w-fit'
            >
              <span className='!hidden sm:!block'>References</span>
            </Button>
          )
        }
        goBack={goBack}
        disabledBack={loading}
        disabledNext={loading}
      />
    </Form>
  )
}

export default GrantInfoStep
