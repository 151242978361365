import { dateFormat, datetimeFormat } from '@/branding-config'

import { sectionToString } from '..'

import { Section, Subsection } from '@/types/document'
import { EnhanceType } from '@/types/grants'

const requiredSections = `- Do research on which sections are required in proposal for selected grant. The document must cover all required sections. The grant proposal must include at least these sections: Introduction, Organizational background, Statement of need, Proposed solution, Alignment with funder's focus, Goals and objectives, Performance metrics, Budget and Conclusion. 

DESCRIPTION OF SECTIONS REQUIRED IN GRANT PROPOSAL:
- Introduction: The introduction must be powerful, compelling, and deeply heartfelt, immediately capturing the reader's attention. It must effectively combine emotional appeal with a clear and urgent presentation of the project's significance, creating a strong desire to read further. The tone must strike the perfect balance between professional and passionate, making a lasting impression and setting the stage for a persuasive proposal.

- Organizational background: The organizational background must be comprehensive and strategic, it must present a detailed history, mission, and significant accomplishments that directly support the proposed project. The narrative must strongly establish the company's credibility and capacity to achieve the project's objectives, with clear, concrete examples of past successes and alignment with the funder's goals. Every aspect of the company's background must be presented in a way that enhances the case for funding.
  Section text and subsections must encompass the following elements as part of JSON:
  1. Begin with a concise statement of the organization's mission, vision, and core values, establishing a clear connection between the organization's purpose and the proposed project.
  2. Provide a brief history of the organization, highlighting significant achievements, growth, and milestones that demonstrate a strong foundation and progressive impact in the field.
  3. Describe the organization's specific expertise, unique skills, or specialized resources that position it as a trusted leader equipped to execute the proposed project effectively.
  4. Share examples of successful past projects or initiatives that are relevant to the proposal, showcasing proven outcomes, measurable impact, and alignment with similar goals.
  5. Highlight established relationships with community members, partners, and stakeholders, underscoring the organization's trusted presence and collaborative network.

- Statement of need: The statement of need must be thoroughly articulated, with a strong, evidence-based case for the urgency and relevance of the problem. The narrative must use high-quality data and research to illustrate the issue and its impacts, making a clear connection between the need and the funder's mission. The case for addressing the problem must be compelling and align seamlessly with the funder's priorities, leaving no question as to the importance of the project.
  Section text and subsections must encompass the following elements as part of JSON:
  1. Begin with a concise and compelling description of the specific issue or gap the project aims to address, immediately establishing the relevance and urgency of the need.
  2. Use recent, credible data and research findings to substantiate the need, incorporating statistics, case studies, or community feedback to emphasize the problem's scope and impact.
  3. Place the issue within a broader regional, national, or industry context, showing how the need aligns with larger trends, challenges, or strategic priorities.
  4. Describe how the issue affects the target population or community, using specific examples, stories, or testimonials to illustrate the human impact and build empathy.
  5. Outline the potential risks or negative outcomes if the need is not addressed, reinforcing the importance of timely intervention and the unique opportunity for the funder to make a difference.
  6. Tie the identified need directly to the funder's mission or priorities, demonstrating why this project is an ideal fit for their support and capable of achieving shared goals.

- Proposed solution: The proposed solution must be highly detailed, innovative, and well thought out. It must directly address the identified need in a compelling and strategic manner, providing a clear and feasible plan for implementation. The solution must demonstrate deep alignment with the funder's priorities, incorporating creative approaches and showing a thorough understanding of the challenges involved. The proposal must convincingly argue for the effectiveness and potential impact of the solution, leaving little doubt as to its value and feasibility.
  Section text and subsections must encompass the following elements as part of JSON:
  1. Outline the proposed solution in clear, concise terms, detailing the core activities, strategies, and methods that will directly address the identified need.
  2. Explain how the proposed solution is grounded in proven practices, research, or successful pilot projects, demonstrating its likelihood of achieving the desired outcomes.
  3. Highlight any unique or innovative aspects of the solution that set it apart from traditional approaches, showing how it brings fresh perspectives or new efficiencies to the issue.
  4. Provide an overview of the step-by-step plan for executing the solution, including key activities, timelines, and assigned responsibilities, to illustrate a well-structured approach.
  5. Describe how the solution is designed for long-term impact, addressing its potential to be scaled, replicated, or sustained beyond the funding period for lasting benefits.
  6. Define the specific, measurable outcomes expected from the solution, linking them to the broader goals and priorities of the funder to reinforce the project's alignment and anticipated value.

- Alignment with funder's focus: The proposal must be deeply aligned with the funder's mission, showcasing a nuanced understanding of the funder's priorities. The narrative must integrate these priorities seamlessly into the proposed project, demonstrating exceptional strategic alignment. Even within strict character limits, the proposal concisely and powerfully conveys alignment, ensuring that every word reinforces the connection with the funder's goals.
  Section text and subsections must encompass the following elements as part of JSON:
  1. Clearly illustrate how the project's goals align with the funder's mission and stated priorities, explicitly linking key objectives to the funder's primary areas of focus.
  2. Demonstrate how the project supports the funder's long-term vision, showing how its outcomes will contribute to larger, strategic goals (e.g., community impact, sustainability, economic growth).
  3. Highlight shared values, such as innovation, equity, or community engagement, and describe how the project embodies these principles in ways that resonate with the funder's priorities.
  4. Outline opportunities for the funder to gain visibility through this partnership, including branding, press releases, etc.

- Goals and objectives: The goals and objectives must be exceptionally clear, specific, and directly aligned with the identified need and the funder's priorities. They must be well-defined (specific), measurable, achievable, relevant, and time-bound (SMART), creating a strong framework for project success. Each objective must be tied to concrete outcomes, and the narrative must clearly explain how these objectives will be achieved and measured, making a compelling case for the funder's support.
  Section text and subsections must encompass the following elements as part of JSON:
  1. Begin with overarching goals that capture the desired long-term impact of the project, aligning them with the funder's mission and vision for maximum resonance.
  2. Define objectives in precise, measurable terms, ensuring each objective is actionable, time-bound, and directly linked to achieving the project's broader goals.
  3. Arrange objectives in a logical sequence that outlines a clear pathway from project initiation to completion, demonstrating a structured approach to achieving outcomes.
  4. Ensure each objective directly addresses aspects of the stated need, reinforcing the proposal's coherence and the solution's responsiveness to the identified problem.
  5. Include specific benchmarks or indicators for each objective, providing concrete measures that funders can use to track progress and assess the project's effectiveness.

- Performance metrics: Performance metrics must be comprehensive, specific, and fully measurable. The proposal must include a robust plan for tracking, assessing, and reporting progress, with clear, relevant indicators of success tied directly to the project's goals and objectives. The narrative must demonstrate a high level of accountability, providing confidence in the project's ability to achieve and measure its desired outcomes. Each metric must be realistic and strategically chosen to align with funder expectations and priorities.
  Section text and subsections must encompass the following elements as part of JSON:
  1. Identify specific, measurable indicators for each project objective, ensuring that each metric can accurately track progress and assess impact.
  2. Establish baseline data to provide a starting point, along with target outcomes to show anticipated improvements or achievements by the end of the project.
  3. Set periodic milestones throughout the project timeline to enable ongoing tracking, making it easy to assess progress and make adjustments as needed.
  4. Emphasize metrics that measure meaningful outcomes and long-term impact on the target population, rather than just activities or outputs.
  5. Choose metrics that resonate with the funder's definition of success, reinforcing how the project directly supports their priorities and desired impact.
  6. Outline the specific methods and frequency for data collection (e.g., surveys, interviews, or analytics), demonstrating a systematic approach to gathering reliable data for reporting.

- Budget: The budget must be comprehensive, meticulously detailed, and fully justified. Every line item must be clearly linked to specific activities and outcomes, creating a transparent financial roadmap for the project. The budget must be realistic, strategically aligned with the proposal's goals, and must leave no questions about how funds will be used. It must reflect an in-depth understanding of project needs and funder expectations, providing funders with confidence in financial stewardship.
  Section text and subsections must encompass the following elements as part of JSON:
  1. Provide a detailed list of expenses, including specific line items for personnel, materials, equipment, and other relevant costs, ensuring each expense is directly tied to project activities.
  2. Offer brief explanations for each budget item, showing how it contributes to achieving project objectives and supporting the overall impact.
  3. Use current market rates and accurate estimates for each item, ensuring the budget is both realistic and sufficient to cover all necessary project components.
  4. If applicable, include in-kind contributions or matching funds, detailing these additional resources to demonstrate the organization's commitment and leveraging of other support.

- Conclusion: The conclusion must be deeply compelling and heartfelt, providing a powerful wrap-up that reinforces the proposal's key points and leaves the reader with a strong emotional pull. The narrative must connect emotionally with the reader, emphasizing the project's significance and potential impact. The tone must be inspiring, leaving a lasting impression that compels the reader to support the project.
  Section text and subsections must encompass the following elements as part of JSON:
  1. Begin by restating the core purpose of the project and why it addresses a critical need, emphasizing its alignment with the funder's priorities.
  2. Briefly highlight the project's primary benefits and expected outcomes, reinforcing the positive, lasting impact it will have on the target community or issue.
  3. Reiterate the organization's expertise, resources, and track record, assuring the funder of the organization's ability to deliver results and manage funds responsibly.
  4. Express appreciation for the funder's consideration, emphasizing how the project aligns with their mission and values, and framing the grant as a mutually beneficial partnership.
  5. End with a compelling call to action, inviting the funder to partner in this transformative effort and join in achieving meaningful change.`

const additionalRules = `ADDITIONAL RULES:
- DO NOT SHORTEN ANY TEXT.
- INSTEAD OF CHARACTER "\\n" ALWAYS USE AN ACTUAL NEW LINE.
- DO NOT USE DOUBLE PARENTHESES.
- DO NOT USE BACKSLASHES.
- THE TITLE OF THE SUBSECTION MUST BE IN PROPERTY "TITLE" AND MUST NOT BE INCLUDED IN PROPERTY "TEXT".
- FOR DATES ALWAYS USE AMERICAN DATE FORMAT: ${dateFormat}
- FOR DATE TIME ALWAYS USE AMERICAN DATE TIME FORMAT: ${datetimeFormat}
- RESPOND IN JSON.`

export const enhanceSubsectionPrompt = (
  subsection: Subsection,
  type: EnhanceType,
  sections?: Section[],
  enhanceInstructions?: string
) => {
  if (type === EnhanceType.LONGER) {
    return `RESPOND IN JSON.
CONTEXT:
${sections?.map((s) => sectionToString(s)).join('\n')}


TASK:
Your goal is to enhance the following grant proposal subsection by expanding on its content, making it significantly more detailed and thorough. The resulting text should be more elaborate and descriptive.

${sectionToString(subsection)}


RESPONSE FORMAT:
Return the enhanced subsection in valid JSON with the following structure:
{
  "title": "Subsection Title without 'Enhanced', 'Improved', 'Expanded' or similar adjectives that would show it was enhanced",
  "text": "Expanded and very long and detailed subsection text in markdown, AT LEAST 5000 CHARACTERS LONG",
}


ENHANCEMENT INSTRUCTIONS:

${enhanceInstructions ? `<IMPORTANT>You must follow these specific guidelines for enhancement above all else: ${enhanceInstructions}</IMPORTANT>` : ''}

- Ensure that the enhanced text is very long, AT LEAST TWICE AS LONG as the original. 2 times more words than the original text.
- Expand on every idea, providing additional context, explanation, and examples where relevant.
- Use the same tone and style as the original text. Use the same structure and formatting. You must mimic the original author's style.
- Add more very detailed and specific information to the section.
- Add more numbers and specific timelines and budget numbers.
- Ensure that titles are clear, concise, and descriptive. Title should be in property "title" and not be included in property "text". Do not add "enhanced", "improved", "expanded" or similar adjectives to the title!
- Always return subsection text in valid markdown format. INSTEAD OF CHARACTER "\\n" ALWAYS USE AN ACTUAL NEW LINE.
- When listing items in subsection text use numbered or bulleted lists in markdown format where appropriate.
${requiredSections}

${additionalRules}`
  } else if (type === EnhanceType.SHORTER) {
    return `RESPOND IN JSON.
CONTEXT:
${sections?.map((s) => sectionToString(s)).join('\n')}


TASK:
Your goal is to enhance the following grant proposal subsection by shortening its content, making it significantly more concise while keeping all important information. The resulting text should be more concise and straight to the point.

${sectionToString(subsection)}


RESPONSE FORMAT:
Return the enhanced subsection in valid JSON with the following structure:
{
  "title": "Subsection Title without 'Enhanced', 'Improved', 'Expanded' or similar adjectives that would show it was enhanced",
  "text": "Shortened and very concise and detailed subsection text in markdown",
}


ENHANCEMENT INSTRUCTIONS:

${enhanceInstructions ? `<IMPORTANT>You must follow these specific guidelines for enhancement above all else: ${enhanceInstructions}</IMPORTANT>` : ''}

- Ensure that the enhanced text is shorter and more concise than the original.
- Use the same tone and style as the original text. Use the same structure and formatting. You must mimic the original author's style.
- Ensure that titles are clear, concise, and descriptive. Title should be in property "title" and not be included in property "text". Do not add "enhanced", "improved", "expanded" or similar adjectives to the title!
- Always return subsection text in valid markdown format. INSTEAD OF CHARACTER "\\n" ALWAYS USE AN ACTUAL NEW LINE.
- When listing items in subsection text use numbered or bulleted lists in markdown format where appropriate.
${requiredSections}

${additionalRules}`
  } else {
    return `RESPOND IN JSON.
CONTEXT:
${sections?.map((s) => sectionToString(s)).join('\n')}


TASK:
Your goal is to enhance the following grant proposal subsection by expanding on its content, making it significantly more detailed and thorough. The resulting text should be more elaborate and descriptive. You must use the search and scrape function to gather new data and information to use in the enhanced text.

${sectionToString(subsection)}


RESPONSE FORMAT:
Return the enhanced subsection in valid JSON with the following structure:
{
  "title": "Subsection Title without 'Enhanced', 'Improved', 'Expanded' or similar adjectives that would show it was enhanced",
  "text": "Expanded and very long and detailed subsection text in markdown, AT LEAST 5000 CHARACTERS LONG",
}


ENHANCEMENT INSTRUCTIONS:

${enhanceInstructions ? `<IMPORTANT>You must follow these specific guidelines for enhancement above all else: ${enhanceInstructions}</IMPORTANT>` : ''}

- CONDUCT WEB SEARCHES AND ACCESS REAL-TIME DATA DIRECTLY WITH THE SEARCH AND SCRAPE FUNCTION TO GATHER NEW DATA AND INFORMATION TO USE IN THE ENHANCED TEXT.
- Ensure that the enhanced text is very long, AT LEAST TWICE AS LONG as the original. 2 times more words than the original text.
- Expand on every idea, providing additional context, explanation, and examples where relevant.
- Use the same tone and style as the original text. Use the same structure and formatting. You must mimic the original author's style.
- Add more very detailed and specific information to the section.
- Add more numbers and specific timelines and budget numbers.
- Ensure that titles are clear, concise, and descriptive. Title should be in property "title" and not be included in property "text". Do not add "enhanced", "improved", "expanded" or similar adjectives to the title!
- Always return subsection text in valid markdown format. INSTEAD OF CHARACTER "\\n" ALWAYS USE AN ACTUAL NEW LINE.
- When listing items in subsection text use numbered or bulleted lists in markdown format where appropriate.
${requiredSections}

${additionalRules}`
  }
}
