import dayjs from 'dayjs'

import { datetimeFormat } from '@/branding-config'
import { getAnswerDraft } from '@/service/Chatbot'

import enhance from './enhance'
import generateSection from './generateSection'
import updateGraphsOfSection from './graph'

import { User } from '@/types'
import { IQuestion } from '@/types/chatbot'

const draftAnswer = async (
  question: string,
  questions: IQuestion[],
  agentId: string,
  user?: User,
  selectedConversation?: string,
  json?: boolean,
  domain?: boolean
) => {
  let answer = await getAnswerDraft(
    domain === undefined ? true : domain,
    question,
    1,
    questions,
    agentId,
    undefined,
    true,
    false,
    user?.email,
    selectedConversation,
    json
  )
  if (!answer?.answer) {
    throw new Error('Failed to draft answer.')
  }
  answer = answer.answer
    .replace(/<!>metadata:.*?<!>/g, '')
    .replace(/<function>.*?<\/function>/g, '')
  return answer
}

const createTmpMessage = (question: string, agentId: string): IQuestion => {
  return {
    question: question,
    messages: [
      {
        role: 'user',
        type: 'text',
        domain: true,
        message: question,
        agent: agentId,
        drafts: [],
        documents: [],
        titles: [],
        googleDriveUrls: [],
        timestamp: dayjs().format(datetimeFormat),
        isCommand: false,
        feedbackScore: 0,
      },
    ],
  }
}

export {
  createTmpMessage,
  draftAnswer,
  enhance,
  generateSection,
  updateGraphsOfSection,
}
