'use client'

import { RiBook2Line, RiSearchLine, RiSparklingFill } from '@remixicon/react'
import { Button, Form, Input, message, Popover } from 'antd'
import { jsonrepair } from 'jsonrepair'
import { SetStateAction, useEffect, useState } from 'react'

import useGrantApplicationState from '@/hooks/context/useGrantApplicationState'
import useModalsState from '@/hooks/context/useModalsState'

import ReferencesModal from '@/components/Chatbot/Output/Answer/ReferencesModal'

import { cn } from '@/utils/clsx'
import { grantAlignmentPrompt } from '@/utils/prompts'

import SelectGrantCard from './SelectGrantCard'
import LoadingScreen from '../LoadingScreen'
import ProgressButtons from '../ProgressButtons'
import lottieGrantsAnimation from '../../../../public/lottieGrants.json'

import { GrantApplicationMode } from '@/types/grants'

interface SelectGrantStepProps {
  getAnswerWrapper: (question: string, streaming: boolean) => Promise<void>
  progressException: boolean
  loading?: boolean
  setLoading: (value: SetStateAction<boolean>) => void
  retry: (additionalInfo: string) => void
  goBack: () => void
  setProgressException: (progressException: boolean) => void
  finishedSteps: number
  setFinishedSteps: (value: SetStateAction<number>) => void
}

const SelectGrantStep: React.FC<SelectGrantStepProps> = ({
  getAnswerWrapper,
  loading,
  setLoading,
  progressException,
  retry,
  goBack,
  setProgressException,
  finishedSteps,
  setFinishedSteps,
}) => {
  const [form] = Form.useForm()
  const { setCurrentStep, currentStep, steps, questions, setSteps, mode } =
    useGrantApplicationState()
  const { toggleModal } = useModalsState()
  const [open, setOpen] = useState(false)
  const [additionalInfo, setAdditionalInfo] = useState('')
  const [text, setText] = useState<string>()
  const [selectedGrant, setSelectedGrant] = useState<number>()

  useEffect(() => {
    setFinishedSteps(0)
  }, [])

  useEffect(() => {
    if (!loading) {
      try {
        JSON.parse(
          jsonrepair(
            questions[questions.length - 1]?.messages[1]?.message as string
          )
        )
        setText(questions[questions.length - 1]?.messages[1]?.message)
        setProgressException(false)
        setSteps({
          ...steps,
          [currentStep]: {
            numQuestions: 1,
          },
        })
      } catch (e) {
        message.error(
          'Error occurred while analyzing grants. Please try again.'
        )
        setText(undefined)
        setProgressException(true)
      }
    }
  }, [questions[questions.length - 1]?.messages[1]?.message, loading])

  const next = async () => {
    if (mode === GrantApplicationMode.MATCHING) {
      setCurrentStep(10)
    } else {
      setCurrentStep(currentStep + 1)
    }
    setLoading(true)

    let grant
    if (text && selectedGrant !== undefined) {
      grant = JSON.parse(jsonrepair(text)).grants[selectedGrant]
    }
    await getAnswerWrapper(grantAlignmentPrompt(JSON.stringify(grant)), true)
  }

  return (
    <Form form={form} onFinish={next} autoComplete='off' labelAlign='left'>
      <div className='flex h-[calc(100vh-110px)] w-full overflow-y-auto p-2'>
        <div
          id='scroller'
          className={cn(
            'm-auto flex w-full min-h-full flex-col rounded-lg bg-surface p-6 text-left text-on-surface sm:max-w-[70em] dark:bg-dark-surface dark:text-dark-on-surface',
            !text || loading ? 'h-auto' : ''
          )}
        >
          {text && !loading ? (
            <>
              <div className='flex h-full grow flex-col gap-6'>
                <div className='markdown-answer grow justify-between break-words text-left text-sm sm:text-base'>
                  <div className='flex flex-col gap-4'>
                    <p className='mb-4'>
                      Select one of the grants listed below. If you don't select
                      a grant, GrantAI will automatically choose the best
                      fitting grant for you.
                    </p>
                    {JSON.parse(jsonrepair(text)).grants?.map(
                      (grant: any, index: number) => (
                        <SelectGrantCard
                          key={index}
                          index={index}
                          name={grant.name}
                          funder={grant.funder}
                          email={grant.email}
                          url={grant.url}
                          geography={grant.geography}
                          type={grant.type}
                          range={grant.range}
                          alignment={grant.alignment}
                          eligibility={grant.eligibility}
                          deadline={grant.deadline}
                          selectedGrant={selectedGrant}
                          setSelectedGrant={setSelectedGrant}
                        />
                      )
                    )}
                  </div>
                </div>
              </div>
              <div id='anchor' className='opacity-0'>
                a
              </div>
            </>
          ) : (
            <LoadingScreen
              finishedSteps={finishedSteps}
              allSteps={4}
              exception={progressException}
              lottieAnimation={lottieGrantsAnimation}
              text={
                progressException
                  ? 'Error occurred while trying to fetch all available grants'
                  : [
                      'Analyzing your company details for grant eligibility...',
                      'Identifying relevant funding categories for your business...',
                      'Scanning the web for available grant opportunities...',
                      'Gathering data on potential funding sources...',
                      'Evaluating grant criteria for a perfect match...',
                      'Reviewing eligibility requirements for each grant...',
                      'Shortlisting the most suitable grants for your company...',
                      'Analyzing funding amounts and deadlines...',
                      'Finalizing the best grant options tailored to your business...',
                      'Compiling your personalized list of top grant opportunities...',
                    ]
              }
              retry={() => retry('')}
              timeInterval={20000}
            />
          )}
        </div>
      </div>
      <ReferencesModal
        documents={
          questions[questions.length - 1]?.messages[1]?.documents ?? []
        }
      />
      <ProgressButtons
        additionalButtons={
          text &&
          !loading &&
          !progressException && (
            <div className='flex gap-2'>
              <Button
                disabled={open}
                onClick={() => toggleModal('references')}
                icon={<RiBook2Line className='size-5' />}
              >
                <span className='!hidden sm:!block'>References</span>
              </Button>
              <Popover
                trigger='click'
                className='cursor-pointer'
                overlayClassName='w-full max-w-md'
                onOpenChange={setOpen}
                content={
                  <Form
                    autoComplete='off'
                    className='flex w-full flex-col items-end'
                    layout='vertical'
                  >
                    <Form.Item
                      name='additionalInfo'
                      rules={[{ type: 'string' }]}
                      className='w-full'
                    >
                      <Input.TextArea
                        rows={5}
                        placeholder='Tell us more about the types of grants you are interested in or any specific instructions to help us find the best matches for you!'
                        onChange={(e) => setAdditionalInfo(e.target.value)}
                        className='w-full'
                      />
                    </Form.Item>
                    <Form.Item className='m-0'>
                      <Button
                        className='flex items-center'
                        htmlType='submit'
                        type='primary'
                        onClick={() => {
                          retry(additionalInfo)
                          setAdditionalInfo('')
                          setOpen(false)
                          setText(undefined)
                          setFinishedSteps(0)
                        }}
                        icon={<RiSparklingFill className='size-5' />}
                      >
                        Search again
                      </Button>
                    </Form.Item>
                  </Form>
                }
              >
                <Button
                  className='flex items-center'
                  disabled={open}
                  icon={<RiSearchLine className='size-5' />}
                >
                  <span className='!hidden sm:!block'>Search again</span>
                </Button>
              </Popover>
            </div>
          )
        }
        goBack={goBack}
        disabledBack={!progressException && (!text || loading || open)}
        disabledNext={
          !text ||
          loading ||
          progressException ||
          (open && mode !== GrantApplicationMode.MATCHING)
        }
      />
    </Form>
  )
}

export default SelectGrantStep
