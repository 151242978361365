'use client'

import { RiArrowLeftSLine, RiArrowRightSLine } from '@remixicon/react'
import { Button, Form } from 'antd'

interface ProgressButtonsProps {
  additionalButtons?: React.ReactNode
  goBack: () => void
  goNext?: () => void
  nextText?: string
  disabledBack?: boolean
  disabledNext?: boolean
  onlyBackButton?: boolean
}

const ProgressButtons: React.FC<ProgressButtonsProps> = ({
  additionalButtons,
  goBack,
  goNext,
  nextText,
  disabledBack,
  disabledNext,
  onlyBackButton,
}) => {
  return (
    <div className='bottom-0 flex w-full items-center justify-between gap-2 bg-surface px-4 py-2 transition-none dark:bg-dark-surface'>
      <Button
        onClick={goBack}
        icon={<RiArrowLeftSLine className='size-5' />}
        disabled={disabledBack}
      >
        <span className='!hidden lg:!flex'>Back</span>
      </Button>
      {additionalButtons}
      {!onlyBackButton && (
        <Form.Item className='m-0'>
          <Button
            onClick={goNext}
            iconPosition='end'
            icon={<RiArrowRightSLine className='size-5' />}
            htmlType='submit'
            type='primary'
            disabled={disabledNext}
          >
            <span className='!hidden lg:!flex'>
              {nextText ? nextText : 'Next'}
            </span>
          </Button>
        </Form.Item>
      )}
    </div>
  )
}

export default ProgressButtons
