'use client'

import { Form } from 'antd'

import useDrawerState from '@/hooks/context/useDrawerState'
import useGrantApplicationState from '@/hooks/context/useGrantApplicationState'

import { companyInfoPrompt } from '@/utils/prompts'

import ProgressButtons from '../ProgressButtons'
import ImportBody from '../../Admin/Modals/FileHub/ImportBody'

interface UploadDocumentStepProps {
  getAnswerWrapper: (question: string, streaming: boolean) => Promise<void>
  goBack: () => void
}

const UploadDocumentStep: React.FC<UploadDocumentStepProps> = ({
  getAnswerWrapper,
  goBack,
}) => {
  const [form] = Form.useForm()
  const { setCurrentStep, currentStep, steps } = useGrantApplicationState()
  const { selectedConversation } = useDrawerState()

  const finish = async () => {
    setCurrentStep(currentStep + 1)
    await getAnswerWrapper(companyInfoPrompt(steps[0]), true)
  }

  return (
    <Form
      form={form}
      onFinish={finish}
      autoComplete='off'
      requiredMark='optional'
      layout='vertical'
    >
      <div className='flex h-[calc(100vh-110px)] w-full overflow-y-auto p-2'>
        <div className='m-auto flex min-h-full w-full flex-col gap-6 rounded-lg bg-surface p-6 text-left text-on-surface sm:max-w-[70em] dark:bg-dark-surface dark:text-dark-on-surface'>
          <div className='flex flex-col'>
            <p className='mb-4'>
              Add documents here to give the AI more information to match you
              with grants, and draft the proposal.
            </p>
            <ImportBody conversationId={selectedConversation} />
          </div>
        </div>
      </div>
      <ProgressButtons goBack={goBack} />
    </Form>
  )
}

export default UploadDocumentStep
