'use client'
import { Form, Input, Radio, Space } from 'antd'

import useDrawerState from '@/hooks/context/useDrawerState'
import useGrantApplicationState from '@/hooks/context/useGrantApplicationState'

import { feedbackPrompt } from '@/utils/prompts'

import LoadingScreen from '../LoadingScreen'
import ProgressButtons from '../ProgressButtons'
import lottieGrantsAnimation from '../../../../public/lottieGrants.json'

interface FeedbackStepProps {
  getAnswerWrapper: (question: string, streaming: boolean) => Promise<void>
  loading?: boolean
  goBack: () => void
}

const FeedbackStep: React.FC<FeedbackStepProps> = ({
  getAnswerWrapper,
  loading,
  goBack,
}) => {
  const [form] = Form.useForm()
  const { steps, currentStep, setSteps } = useGrantApplicationState()
  const { setSelectedConversation } = useDrawerState()

  const finish = async (values: { [x: string]: string }) => {
    setSteps({
      ...steps,
      [currentStep]: {
        ...steps[currentStep],
        values,
      },
    })
    await getAnswerWrapper(feedbackPrompt(values), false)
    setSelectedConversation(undefined)
  }

  return (
    <Form
      form={form}
      onFinish={finish}
      autoComplete='off'
      labelAlign='left'
      initialValues={steps[currentStep]}
    >
      <div className='flex h-[calc(100vh-110px)] w-full overflow-y-auto p-2'>
        <div className='m-auto flex min-h-full w-full flex-col rounded-lg bg-surface p-6 text-left text-on-surface sm:max-w-[70em] dark:bg-dark-surface dark:text-dark-on-surface'>
          {loading ? (
            <LoadingScreen
              lottieAnimation={lottieGrantsAnimation}
              text='Finalizing grant proposal...'
              infiniteLoader
            />
          ) : (
            <>
              <p>
                Thank you for using GrantAI! To improve your experience, we
                would like to ask a few questions. Your feedback is essential in
                helping us enhance our service.
              </p>

              <div className='flex flex-col gap-2'>
                <div className='pb-2'>
                  How satisfied are you with the Grant AI assistance you
                  received?
                </div>
                <div className='items flex flex-col gap-4'>
                  <Form.Item
                    name='feedback1'
                    className='w-full'
                    rules={[
                      { required: true, message: 'Please select an option.' },
                    ]}
                  >
                    <Radio.Group>
                      <Space direction='vertical'>
                        <Radio value='Extremely satisfied'>
                          Extremely satisfied
                        </Radio>
                        <Radio value='Very satisfied'>Very satisfied</Radio>
                        <Radio value='Moderately satisfied'>
                          Moderately satisfied
                        </Radio>
                        <Radio value='Slightly satisfied'>
                          Slightly satisfied
                        </Radio>
                        <Radio value='Not at all satisfied'>
                          Not at all satisfied
                        </Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </div>
              </div>

              <div className='flex flex-col gap-2'>
                <div className='pb-2'>
                  How would you rate the quality of the proposal Grant AI
                  produced?
                </div>
                <Form.Item
                  name='feedback2'
                  className='w-full max-w-screen-sm'
                  rules={[
                    { required: true, message: 'Please select an option.' },
                  ]}
                >
                  <Radio.Group>
                    <Space direction='vertical'>
                      <Radio value='Excellent'>Excellent</Radio>
                      <Radio value='Very good'>Very good</Radio>
                      <Radio value='Good'>Good</Radio>
                      <Radio value='Fair'>Fair</Radio>
                      <Radio value='Poor'>Poor</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </div>
              <div className='flex flex-col gap-2'>
                <div className='pb-2'>
                  Compared to normal non-assisted grant writing, how would you
                  rate the experience of using Grant AI for writing a grant
                  proposal?
                </div>
                <Form.Item
                  name='feedback3'
                  className='w-full max-w-screen-sm'
                  rules={[
                    { required: true, message: 'Please select an option.' },
                  ]}
                >
                  <Radio.Group>
                    <Space direction='vertical'>
                      <Radio value='Much better'>Much better</Radio>
                      <Radio value='Somewhat better'>Somewhat better</Radio>
                      <Radio value='About the same'>About the same</Radio>
                      <Radio value='Somewhat worse'>Somewhat worse</Radio>
                      <Radio value='Much worse'>Much worse</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </div>
              <div className='flex flex-col gap-2'>
                <div className='pb-2'>
                  What other comments, concerns, or reflections do you have
                  about Grant AI? Please be as specific as possible as your
                  feedback is extremely important to us.
                </div>
                <Form.Item name='feedback4' className='w-full'>
                  <Input.TextArea
                    placeholder='Provide your comments, concerns, or reflections about Grant AI'
                    autoSize={{ minRows: 3, maxRows: 5 }}
                  />
                </Form.Item>
              </div>
              <div className='flex flex-col gap-2'>
                <div className='pb-2'>
                  What other questions should be asked of users of Grant AI
                  during onboarding to providing even better results?
                </div>
                <Form.Item name='feedback5' className='w-full'>
                  <Input.TextArea
                    placeholder='Provide other questions you might have'
                    autoSize={{ minRows: 3, maxRows: 5 }}
                  />
                </Form.Item>
              </div>
            </>
          )}
        </div>
      </div>
      <ProgressButtons
        goBack={goBack}
        nextText='Finish'
        disabledNext={loading}
      />
    </Form>
  )
}

export default FeedbackStep
