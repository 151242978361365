import { citePrompt } from './citePrompt'
import { companyInfoPrompt } from './companyInfoPrompt'
import { enhanceCompanyInfoPrompt } from './enhanceCompanyInfoPrompt'
import { enhanceSectionPrompt } from './enhanceSectionPrompt'
import { enhanceSubsectionPrompt } from './enhanceSubsectionPrompt'
import { feedbackPrompt } from './feedbackPrompt'
import { generateGraphPrompt } from './generateGraphPrompt'
import { generateProposalDraftPrompt } from './generateProposalDraftPrompt'
import { getGrantsPrompt } from './getGrantsPrompt'
import { grantAlignmentPrompt } from './grantAlignmentPrompt'

export {
  citePrompt,
  companyInfoPrompt,
  enhanceCompanyInfoPrompt,
  enhanceSectionPrompt,
  enhanceSubsectionPrompt,
  feedbackPrompt,
  generateGraphPrompt,
  generateProposalDraftPrompt,
  getGrantsPrompt,
  grantAlignmentPrompt,
}
